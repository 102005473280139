<template>
  <v-card>
    <v-card-title class="primary white--text justify-center">
      Режимы сварки и материалы
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <Layers/>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Дополнителные параметры"
            filled
            hide-details
            rows="5"
            :value="card.others"
            @input="setCard({ others: $event })"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Layers from '../Layers.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  components: { Layers },
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
  },
  methods: {
    ...mapMutations('specialCard', { setCard: 'setNewCardField' })
  }
}
</script>
