<template>
  <v-dialog
    value="show"
    persistent
    :overlay="false"
    max-width="500px"
    scrollable
    >
    <v-card>
      <NewWeldOrderDialog
        v-if="newWeldOrderDialogShow"
        @close="newWeldOrderDialogShow = false"
        @input="getWeldOrderItems"
      />
      <v-card-title class="primary white--text justify-center" style="word-break: break-word;">
        Порядок сварки
      </v-card-title>
      <v-card-text class="text-center">
          <v-autocomplete
            v-model="weldOrder"
            :items="weldOrderItems"
            label="Найти порядок сварки"
            autocomplete="off"
            item-text="name"
            return-object
            @change="select"
            >
            <template v-slot:item="data">
              <div class="text-center" style="width: 100%;">
                <span class="mr-5">{{data.item.name}}</span>
                <v-list-item-avatar tile size="100">
                  <img :src="data.item.thumbnail">
                </v-list-item-avatar>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="pt-2 text-center">
              <v-btn color="success" @click="newWeldOrderDialogShow = true">Создать</v-btn>
              </div>
            </template>
          </v-autocomplete>
        </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NewWeldOrderDialog from './NewWeldOrderDialog.vue'
export default {
  components: { NewWeldOrderDialog },
  data: () => ({
    newWeldOrderDialogShow: false,
    weldOrderItems: [],
    weldOrder: ''
  }),
  methods: {
    select(e) {
      this.$emit('close')
      this.$emit('input', e)
    },
    getWeldOrderItems() {
      this.$axios.get('/special-card/weld-order/index').then(r => {
        this.weldOrderItems = r.data.slice()
      })
    },
  },
  mounted: function() {
    this.getWeldOrderItems()
  }
}
</script>
