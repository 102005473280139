<template>
  <v-card>
    <v-card-title class="primary white--text justify-center">
      Конструкция соединения, конструктивные элементы шва и порядок сварки
    </v-card-title>
    <AddJointConstructDialog
      v-if="addJointConstructDialogShow"
      @close="addJointConstructDialogShow = false"
      @input="getJointConstrucItems"
    />
    <AddWeldOrderDialog
      v-if="addWeldOrderDialogShow"
      @close="addWeldOrderDialogShow = false"
      @input="addWeldOrder"
    />
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="card.jointConstruct"
            :items="jointConstructItems"
            label="Конструкция соединения"
            autocomplete="off"
            item-text="name"
            item-value="id"
            return-object
            @change="setCard({ jointConstruct: {
              id: $event.id,
              name: $event.name,
              jointUrl: $event.jointUrl,
              jointText: $event.jointText,
              seamUrl: $event.seamUrl,
              seamText: $event.seamText
            }})"
            >
            <template v-slot:item="data">
              <div class="text-center" style="width: 100%;">
                <span class="mr-5">{{data.item.name}}</span>
                <v-list-item-avatar tile size="100">
                  <img :src="data.item.jointThumbnail">
                </v-list-item-avatar>
                <v-list-item-avatar tile size="100">
                  <img :src="data.item.seamThumbnail">
                </v-list-item-avatar>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="pt-2 text-center">
                <v-btn color="success" @click="addJointConstructDialogShow = true">
                  Создать
                </v-btn>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="card.jointConstruct.jointUrl" cols="6">
          <div class="bordered text-center">
            <div class="heading pa-3">Конструкция соединения</div>
            <img :src="card.jointConstruct.jointUrl" style="max-height: 200px;"/>
            <div class="heading pa-3">{{card.jointConstruct.jointText}}</div>
          </div>
        </v-col>
        <v-col v-if="card.jointConstruct.seamUrl" cols="6">
          <div class="bordered text-center">
            <div class="heading pa-3">Конструктивные элементы шва</div>
            <img :src="card.jointConstruct.seamUrl" style="max-height: 200px;"/>
            <div class="heading pa-3">{{card.jointConstruct.seamText}}</div>
          </div>
        </v-col>
      </v-row>
      <div class="body-1">Порядок сварки</div>
      <div class="bordered pa-2">
        <v-row align="center">
          <v-col
            cols="3"
            class="text-center"
            v-for="(ord, i) in card.weldOrder"
            :key="'ord_' + i"
            >
            <div class="heading">
              {{ord.name}}
            </div>
            <div class="bordered" style="width: 100%; height: 200px">
              <v-icon color="red" @click="removeWeldOrder(ord)" style="float: right;">
                mdi-close
              </v-icon>
              <img :src="ord.url" style="height: 100%; padding: 10px 0 10px 24px;"/>
            </div>
            <div class="heading">{{ord.text}}</div>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-btn color="success" @click="addWeldOrderDialogShow = true">Добавить</v-btn>
          </v-col>
        </v-row>
        <v-text-field
          label="Примечание"
          :value="card.weldOrderCommit"
          @input="setCard({ weldOrderCommit: $event })"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AddJointConstructDialog from '../AddJointConstructDialog.vue'
import AddWeldOrderDialog from '../AddWeldOrderDialog.vue'

export default {
  components: {
    AddJointConstructDialog,
    AddWeldOrderDialog,
  },
  data: () => ({
    jointConstructItems: [],
    addJointConstructDialogShow: false,
    addWeldOrderDialogShow: false,
  }),
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
  },
  methods: {
    ...mapMutations('specialCard', {
      setCard: 'setNewCardField',
      addWeldOrder: 'addWeldOrder',
      removeWeldOrder: 'removeWeldOrder'
    }),
    getJointConstrucItems() {
      this.$axios.get('/special-card/joint/index').then(r => {
        this.jointConstructItems = r.data.slice()
      })
    }
  },
  mounted: function() {
    this.getJointConstrucItems()
  }
}
</script>
