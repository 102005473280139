var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.layers),function(layer,i){return _c('v-card',{key:i,staticClass:"mb-3"},[_c('div',{staticClass:"secondary white--text text-center pa-2 d-flex"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"},on:{"click":function($event){return _vm.up(layer.num)}}},[_vm._v("mdi-arrow-up")]),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.down(layer.num)}}},[_vm._v("mdi-arrow-down")]),_c('v-spacer'),_vm._v(" Проход: "+_vm._s(layer.num)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"},on:{"click":function($event){return _vm.copy(i)}}},[_vm._v("mdi-plus-one")]),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.remove(i)}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Слой","items":_vm.filteredLayers,"item-text":"name","item-value":"id","value":layer.nameId},on:{"input":function($event){
var _obj;
return _vm.setLayerField(( _obj = {}, _obj[i] = { nameId:  $event}, _obj ))}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Марка сварочного материала","value":layer.weldMaterial},on:{"input":function($event){
var _obj;
return _vm.setLayerField(( _obj = {}, _obj[i] = { weldMaterial: $event }, _obj ))}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Диаметр электрода","type":"number","step":"0.1","value":layer.electrode},on:{"input":function($event){
var _obj;
return _vm.setLayerField(( _obj = {}, _obj[i] = { electrode: $event }, _obj ))}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Полярность","items":_vm.polaritiesNames,"item-text":"name","item-value":"id","value":layer.polarityNameId},on:{"input":function($event){
var _obj;
return _vm.setLayerField(( _obj = {}, _obj[i] = { polarityNameId: $event }, _obj ))}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"subtitle-1"},[_vm._v("Каналы контроля")]),_c('v-row',{staticClass:"ma-0",staticStyle:{"border-width":"thin","border-style":"solid"}},[_vm._l((layer.channels),function(channel){return _c('v-col',{key:channel.nameId,attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.getChannelNameById(channel.nameId)))]),_c('v-row',{staticClass:"ma-0",staticStyle:{"border-width":"thin","border-style":"solid"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Мин","hide-details":"","dense":"","value":channel.min},on:{"input":function($event){return _vm.setChannelField({
                      layerIndex: i, channelNameId: channel.nameId, value: { min: $event }
                    })}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Макс","hide-details":"","dense":"","value":channel.max},on:{"input":function($event){return _vm.setChannelField({
                      layerIndex: i, channelNameId: channel.nameId, value: { max: $event }
                    })}}})],1)],1)],1)}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success","outlined":"","disabled":layer.channels.length === _vm.channelsNames.length},on:{"click":function($event){return _vm.addLayerChannel(i)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],2)],1)],1)],1)],1)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":_vm.newLayer}},[_vm._v("Добавть слой")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }