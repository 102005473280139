<template>
  <v-card>
    <v-card-title class="primary white--text justify-center">
      Прочая информация
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Операционный контроль"
            filled
            hide-details
            rows="5"
            :value="card.operationControl"
            @input="setCard({ operationControl: $event })"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Техника безопасности и охрана труда"
            filled
            hide-details
            rows="7"
            :value="card.safety"
            @input="setCard({ safety: $event })"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
  },
  methods: {
    ...mapMutations('specialCard', { setCard: 'setNewCardField' })
  }
}
</script>
