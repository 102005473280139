<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title >
            Согласующая сторона
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ФИО"
                  :value="card.agreeder.name"
                  @input="setCardAgreeder({ name: $event })"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Организация"
                  :value="card.agreeder.org"
                  @input="setCardAgreeder({ org: $event })"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Должность"
                  :value="card.agreeder.post"
                  @input="setCardAgreeder({ post: $event })"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Дата"
                  :value="card.agreeder.date"
                  @input="setCardAgreeder({ date: $event })"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            Утверждающая сторона
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ФИО"
                  :value="card.accepter.name"
                  @input="setCardAccepter({ name: $event })"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Организация"
                  :value="card.accepter.org"
                  @input="setCardAccepter({ org: $event })"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Должность"
                  :value="card.accepter.post"
                  @input="setCardAccepter({ post: $event })"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Дата"
                  :value="card.accepter.date"
                  @input="setCardAccepter({ date: $event })"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title >
            Общее описание тех. карты
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Название(№) карты"
                  :value="card.name"
                  @input="setCard({ name: $event })"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  filled
                  rows="4"
                  row-height="30"
                  label="Краткое описание технологической карты"
                  :value="card.about"
                  @input="setCard({ about: $event })"
                />
              </v-col>
              <v-col cols="12">
                <Files :value="card.files" @input="addFile" @removeFile="removeFile"></Files>
              </v-col>
            </v-row>
            <v-btn @click="test"></v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Files from '../Files.vue'

export default {
  components: { Files },
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
  },
  methods: {
    ...mapActions('specialCard', ['addFile', 'removeFile']),
    ...mapMutations('specialCard', {
      setCard: 'setNewCardField',
      setCardAccepter: 'setNewCardAccepterField',
      setCardAgreeder: 'setNewCardAgreederField',
    }),
    test(){
      console.log(this.card.accepter)
    }
  }
}
</script>
