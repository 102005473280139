<template>
  <div>
    <v-card v-for="(layer, i) in layers" :key="i" class="mb-3">
      <div class="secondary white--text text-center pa-2 d-flex">
        <v-icon color="white" class="mr-2" @click="up(layer.num)">mdi-arrow-up</v-icon>
        <v-icon color="white" @click="down(layer.num)">mdi-arrow-down</v-icon>
        <v-spacer></v-spacer>
        Проход: {{layer.num}}
        <v-spacer></v-spacer>
        <v-icon color="white" class="mr-3" @click="copy(i)">mdi-plus-one</v-icon>
        <v-icon color="white" @click="remove(i)">mdi-close</v-icon>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select
              label="Слой"
              :items="filteredLayers"
              item-text="name"
              item-value="id"
              :value="layer.nameId"
              @input="setLayerField({ [i]: { nameId:  $event}})"
              >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Марка сварочного материала"
              :value="layer.weldMaterial"
              @input="setLayerField({ [i]: { weldMaterial: $event }})"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Диаметр электрода"
              type="number" step="0.1"
              :value="layer.electrode"
              @input="setLayerField({ [i]: { electrode: $event }})"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Полярность"
              :items="polaritiesNames"
              item-text="name"
              item-value="id"
              :value="layer.polarityNameId"
              @input="setLayerField({ [i]: { polarityNameId: $event }})"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="subtitle-1">Каналы контроля</div>
            <v-row class="ma-0" style="border-width: thin; border-style: solid;">
              <v-col cols="6" v-for="channel in layer.channels" :key="channel.nameId">
                <div class="subtitle-1">{{getChannelNameById(channel.nameId)}}</div>
                <v-row class="ma-0" style="border-width: thin; border-style: solid;">
                  <v-col cols="6">
                    <v-text-field
                      label="Мин"
                      hide-details
                      dense
                      :value="channel.min"
                      @input="setChannelField({
                        layerIndex: i, channelNameId: channel.nameId, value: { min: $event }
                      })"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Макс"
                      hide-details
                      dense
                      :value="channel.max"
                      @input="setChannelField({
                        layerIndex: i, channelNameId: channel.nameId, value: { max: $event }
                      })"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="success"
                  outlined
                  :disabled="layer.channels.length === channelsNames.length"
                  @click="addLayerChannel(i)"
                  >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-btn color="info" small @click="newLayer">Добавть слой</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({
    layerName: [],
  }),
  computed: {
    ...mapState(['layersNames', 'polaritiesNames', 'channelsNames']),
    ...mapState('specialCard', { card: 'newCard' }),
    ...mapGetters(['getChannelNameById']),
    layers() {
      return this.card.layers.sort((a, b) => a.num - b.num)
    },
    filteredLayers() {
      this.layersNames.forEach(l => {
        l.disabled = false
        if(this.card.layers.some(ll => ll.nameId == l.id)) {
          l.disabled = true
        }
        if(l.id === -1) {
          if(this.card.layers.length != 1) {
            l.disabled = true
          }
        }
        if(l.id === -2) {
          if(this.card.layers.length == 1) {
            l.disabled = true
          }
        }
      })
      return this.layersNames
    },

  },
  methods: {
    ...mapMutations('specialCard', {
      setLayerField: 'setNewCardLayerField',
      setChannelField: 'setNewCardLayerChannelField',
      newLayer: 'newCardLayerCreate',
      up: 'newCardLayerUp',
      down: 'newCardLayerDown',
      copy: 'newCardLayerCopy',
      remove: 'newCardLayerRemove',
      addLayerChannel: 'newCardLayerChannelCreate'
    }),
    selectLayerName(i, e) {
      this.setLayerField({ [i]: { layerNameId: e.id }})
    },
  }
}
</script>
