<template>
  <v-card>
    <v-card-title class="secondary white--text justify-center">
      Технические требования
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            label="Требования к подготовке кромок"
            filled
            hide-details
            :value="card.edgeRequire"
            @input="setCard({ edge: $event })"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Требования к сборке под сварку"
            filled
            hide-details
            :value="card.assemblyRequire"
            @input="setCard({ assemblyRequire: $event })"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Требования к прихватке"
            filled
            hide-details
            :value="card.tackweldRequire"
            @input="setCard({ tackweldRequire: $event })"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Технологические требования к сварке"
            filled
            hide-details
            :value="card.weldRequire"
            @input="setCard({ weldRequire: $event })"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
  },
  methods: {
    ...mapMutations('specialCard', { setCard: 'setNewCardField' })
  }
}
</script>
