<template>
  <v-card>
    <v-card-title class="primary white--text justify-center">
      Сварочное оборудование и дополнительные операции
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            label="Сварочное оборудование"
            item-text="name"
            item-value="id"
            return-object
            multiple
            chips
            :items="weldEquipmentItems"
            v-model="card.weldEquipment"
            @input="addWeldEquipment($event)"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Предварительный подогрев"
            filled
            hide-details
            rows="4"
            :value="card.preHeat"
            @input="setCard({ preHeat: $event })"
          />
        </v-col>
        <v-col cols="12">
          <div class="body-1">Послесварочная термическая обработка</div>
          <v-container class="bordered">
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  label="Вид обработки"
                  :items="postHeatTypeItems"
                  :value="card.postHeatType"
                  @change="setPostHeatField({ type: $event })"
                />
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  label="Условия охлаждения"
                  :items="postHeatCoolingItems"
                  :value="card.postHeatCooling"
                  @change="setPostHeatField({ cooling: $event })"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Оборудование"
                  multiple
                  item-text="name"
                  item-value="id"
                  return-object
                  chips
                  :items="postHeatEquipmentItems"
                  :value="card.postHeatEquipment"
                  @change="addPostHeatEquipment($event)"
                />
              </v-col>
              <v-col cols="12">
                <div class="body-1">Циклы обработки</div>
                <v-container class="bordered">
                  <v-row no-glutters align="center">
                    <v-col cols="2" v-for="(cycle, i) in card.postHeatCycles">
                      <v-container class="bordered pt-0">
                        <v-text-field
                          label="Температура"
                          hide-details
                          :value="cycle.temperature"
                          @input="setPostHeatCycleField({ [i]: { temperature: $event} })"
                        />
                        <v-text-field
                          label="Выдержка"
                          hide-details
                          :value="cycle.delay"
                          @input="setPostHeatCycleField({ [i]: { delay: $event} })"
                        />
                      </v-container>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="success" @click="addCycle">Добавить</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      weldEquipmentItems: [],
      postHeatEquipmentItems: [],
    }
  },
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
    ...mapState('specialCard', [
      'postHeatTypeItems', 'postHeatCoolingItems',
    ]),
  },
  methods: {
    ...mapMutations('specialCard', {
      setCard: 'setNewCardField',
      addWeldEquipment: 'addNewCardWeldEquipment',
      addCycle: 'addNewCardPostHeatCycle',
      setPostHeatField: 'setNewCardPostHeatField',
      addPostHeatEquipment: 'addNewCardPostHeatEquipment',
      setPostHeatCycleField: 'setNewCardPostHeatCycleField'
    }),
    getPostHeatEquipment() {
      this.$axios.get('special-card/heat-equipment/index').then(r => {
        this.postHeatEquipmentItems = r.data.slice()
      })
    },
    getWeldEquipment() {
      this.$axios.get('special-card/weld-equipment/index').then(r => {
        this.weldEquipmentItems = r.data.slice()
      })
    }
  },
  mounted() {
    this.getWeldEquipment()
    this.getPostHeatEquipment()
  }
}
</script>
