<template>
  <v-card>
    <v-card-title>
      Характеристика соединения и свариваемых элементов
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-combobox
            :value="card.weldMethod"
            :items="weldMethodItems"
            :search-input.sync="weldMethodSearch"
            label="Способ сварки"
            @input="setCard({ weldMethod: $event })"
          />
        </v-col>
        <v-col cols="2">
          <v-combobox
            :value="card.jointType"
            :items="jointTypeItems"
            :search-input.sync="jointTypeSearch"
            label="Тип соединения"
            @input="setCard({ jointType: $event })"
          />
        </v-col>
        <v-col cols="3">
          <v-combobox
            :value="card.normDoc"
            :items="gostItems"
            :search-input.sync="gostSearch"
            label="Нормирующий документ"
            @input="setCard({ normDoc: $event })"
          />
        </v-col>
        <v-col cols="1">
          <v-combobox
            :value="card.seamType"
            :items="seamTypeItems"
            :search-input.sync="seamTypeSearch"
            label="Тип шва"
            @input="setCard({ seamType: $event })"
          />
        </v-col>
        <v-col cols="2">
          <v-combobox
            :value="card.seamKind"
            :items="seamKindItems"
            :search-input.sync="seamKindSearch"
            label="Вид шва соединения"
            @input="setCard({ seamKind: $event })"
          />
        </v-col>
        <v-col cols="2">
          <v-combobox
            :value="card.weldPosition"
            :items="weldPositionItems"
            :search-input.sync="weldPositionSearch"
            label="Положение стыка"
            @input="setCard({ weldPosition: $event })"
          />
        </v-col>
        <v-col cols="6" class="pt-0" v-for="(el, i) in card.elements" :key="'element-' + i">
          <v-container class="bordered">
            <div class="body-1">Элемент {{i + 1}}</div>
            <v-row>
              <v-col cols="3">
                <v-combobox
                  :value="el.type"
                  :items="elementTypeItems"
                  :search-input.sync="elementTypeSearch[i]"
                  label="Тип"
                  @input="setCardElement({ [i]: { type: $event }})"
                />
              </v-col>
              <v-col cols="3">
                <v-combobox
                  :value="el.material"
                  :items="materialItems"
                  :search-input.sync="materialSearch[i]"
                  label="Материал"
                  @input="setCardElement({ [i]: { material: $event }})"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Диаметр"
                  :value="el.d"
                  @input="setCardElement({ [i]: { d: $event }})"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Толщина"
                  :value="el.s"
                  @input="setCardElement({ [i]: { s: $event }})"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    weldMethodSearch: '',
    jointTypeSearch: '',
    gostSearch: '',
    seamTypeSearch: '',
    seamKindSearch: '',
    weldPositionSearch: '',
    elementTypeSearch: [],
    materialSearch: [],
  }),
  computed: {
    ...mapState('specialCard', { card: 'newCard' }),
    ...mapState('specialCard', [
      'jointTypeItems', 'gostItems', 'seamTypeItems', 'seamKindItems',
      'weldPositionItems', 'elementTypeItems', 'materialItems', 'weldMethodItems'
    ]),
  },
  methods: {
    ...mapMutations('specialCard', {
      setCard: 'setNewCardField',
      setCardElement: 'setNewCardElementField',
    })
  }
}
</script>
