<template>
  <v-dialog
    value="show"
    persistent
    :overlay="false"
    max-width="500px"
    scrollable
    >
    <v-card>
      <v-card-title class="primary white--text text-center" style="word-break: break-word;">
        Создание нового порядка сварки
      </v-card-title>
      <v-card-text class="text-center">
        <input type="file" ref="cropFile" style="display: none;" @change="setCropFile">
        <ImageCropDialog
          v-if="cropDialogShow"
          :file="cropFile"
          @done="cropDone"
          @close="cropDialogShow = false"
        />
        <v-text-field
          label="Название"
          v-model="name"
        />
        <img v-if="image"
          :src="fromBlob(image)"
          style="max-width: 200px; max-height: 200px; cursor: pointer;"
          @click="$refs.cropFile.click()"
        />
        <v-icon v-else
          size="200px"
          @click="$refs.cropFile.click()"
          >
          mdi-drawing-box
        </v-icon>
        <v-textarea
          label="Примечание"
          hide-details
          filled
          v-model="text"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Закрыть</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageCropDialog from '@/components/ImageCropDialog.vue'

export default {
  components: { ImageCropDialog },
  data: () => ({
    cropFile: null,
    cropDialogShow: false,
    name: 'test name',
    image: null,
    text: 'joint desc',
  }),
  methods: {
    setCropFile(e) {
      if (typeof FileReader !== 'function') {
        alert('Ваш браузер не проддерживает FileReader API')
        return
      }
      if(!e.target.files[0]) return
      if(!e.target.files[0].type.includes('image/')) {
        alert('Выбранный файл не является изображением')
        return
      }
      this.cropFile = e.target.files[0]
      this.$refs.cropFile.value = ''
      this.cropDialogShow = true
    },
    cropDone(e) {
      this.image = e.image
      this.cropDialogShow = false
    },
    fromBlob(blob) {
      return URL.createObjectURL(blob)
    },
    save() {
      let formData = new FormData()
      let json = {
        name: this.name,
        text: this.text,
      }
      formData.append('json', JSON.stringify(json))
      formData.append('weldOrderFile', new File([this.image], 'weld-order.png'))
      this.$axios.post('special-card/weld-order/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(r => {
        this.$emit('input')
        this.$emit('close')
      })
    },
  },
  watch: {
    show(v) {
      if(v) {
        // this.cropFile = null
        // this.cropDialogShow = false
        // this.img = null
        // this.file = null
        // this.thumbnail = null
        // this.name = ''
        // this.text = ''
      }
    }
  }
}
</script>
