<template>
  <v-dialog
    value="show"
    persistent
    :overlay="false"
    max-width="500px"
    scrollable
    >
    <v-card>
      <v-card-title class="primary white--text text-center" style="word-break: break-word;">
        Создание новой конструкции соединения и элементов шва
      </v-card-title>
      <v-card-text class="text-center">
        <input type="file" ref="cropFile" style="display: none;" @change="setCropFile">
        <ImageCropDialog
          v-if="cropDialogShow"
          :file="cropFile"
          @done="cropDone"
          @close="cropDialogShow = false"
        />
        <v-text-field
          label="Название"
          v-model="name"
        />
        <div class="body-1">Конструкция соединения</div>
        <div class="bordered pa-2">
          <img v-if="jointImage"
            :src="fromBlob(jointImage)"
            style="max-width: 200px; max-height: 200px; cursor: pointer;"
            @click="changeJointImage"
          />
          <v-icon v-else
            size="200px"
            @click="changeJointImage"
            >
            mdi-drawing-box
          </v-icon>
          <v-textarea
            label="Примечание"
            hide-details
            rows="3"
            filled
            v-model="jointDesc"
          />
        </div>
        <div class="body-1 pt-4">Конструктивные элементы шва</div>
        <div class="bordered pa-2">
          <img v-if="seamImage"
            :src="fromBlob(seamImage)"
            style="max-width: 200px; max-height: 200px; cursor: pointer;"
            @click="changeSeamImage"
          />
          <v-icon v-else
            size="200px"
            @click="changeSeamImage"
            >
            mdi-drawing-box
          </v-icon>
          <v-textarea
            label="Примечание"
            hide-details
            rows="3"
            filled
            v-model="seamDesc"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Закрыть</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageCropDialog from '@/components/ImageCropDialog.vue'

export default {
  components: { ImageCropDialog },
  data: () => ({
    cropFile: null,
    cropDialogShow: false,
    isChangeJointImage: false,
    jointImage: null,
    isChangeSeamImage: false,
    seamImage: null,
    name: 'test name',
    jointDesc: 'joint desc',
    seamDesc: 'seam desc',
  }),
  methods: {
    fromBlob(blob) {
      return URL.createObjectURL(blob)
    },
    changeJointImage() {
      this.isChangeJointImage = true
      this.$refs.cropFile.click()
    },
    changeSeamImage() {
      this.isChangeSeamImage = true
      this.$refs.cropFile.click()
    },
    setCropFile(e) {
      if (typeof FileReader !== 'function') {
        alert('Ваш браузер не проддерживает FileReader API')
        return
      }
      if(!e.target.files[0]) return
      if(!e.target.files[0].type.includes('image/')) {
        alert('Выбранный файл не является изображением')
        return
      }
      this.cropFile = e.target.files[0]
      this.$refs.cropFile.value = ''
      this.cropDialogShow = true
    },
    cropDone(e) {
      if (this.isChangeJointImage) {
        this.jointImage = e.image
        this.isChangeJointImage = false
      } else if (this.isChangeSeamImage) {
        this.seamImage = e.image
        this.isChangeSeamImage = false
      }
      this.cropDialogShow = false
    },
    save() {
      let formData = new FormData()
      let text = {
        name: this.name,
        jointText: this.jointDesc,
        seamText: this.seamDesc,
      }
      formData.append('json', JSON.stringify(text))
      const jointFile = new File([this.jointImage], 'jointImage.png')
      formData.append('jointFile', jointFile)
      const seamFile = new File([this.seamImage], 'seamImage.png')
      formData.append('seamFile', seamFile)
      this.$axios.post('special-card/joint/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(r => {
        this.$emit('input')
        this.$emit('close')
      })
    },
  },
  watch: {
    show(v) {
      if(v) {
        // this.cropFile = null
        // this.cropDialogShow = false
        // this.isChangeJointImage = false
        // this.jointImg = null
        // this.jointFile = null
        // this.jointThumbnail = null
        // this.isChangeSeamImage = false
        // this.seamImg = null
        // this.seamFile = null
        // this.seamThumbnail = null
        // this.name = ''
        // this.jointDesc = ''
        // this.seamDesc = ''
      }
    }
  }
}
</script>
