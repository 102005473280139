<template>
  <v-card class="ma-5">
    <v-card-title class="primary justify-center white--text">
      Создание новой карты
    </v-card-title>
    <div class="grey lighten-1">
      <v-stepper
        :value="currentStep"
        @change="setStep"
        >
        <v-stepper-header>
          <template v-for="(it,index) in stepsCount">
            <v-stepper-step
              :step="index + 1"
              :complete="currentStep > index + 1"
              :editable="currentStep > index + 1"
              edit-icon="$complete"
              >
              Шаг {{ index + 1 }}
            </v-stepper-step>
          </template>
        </v-stepper-header>
      </v-stepper>
      <component v-if="cardLoaded && currentStep" :is="'Step' + currentStep"/>
    </div>
    <v-card-actions class="pa-4">
      <v-btn color="default" to="/">Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="scondary" v-if="currentStep > 1" @click="prevStep">Назад</v-btn>
      <v-btn v-if="currentStep != stepsCount" color="primary" @click="nextStep">
        Далее
      </v-btn>
      <v-btn v-else color="success" @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Store from '@/store/index.js'
import { mapState, mapActions } from 'vuex'
import { route } from 'vue-router'
import Step1 from './components/steps/Step1.vue'
import Step2 from './components/steps/Step2.vue'
import Step3 from './components/steps/Step3.vue'
import Step4 from './components/steps/Step4.vue'
import Step5 from './components/steps/Step5.vue'
import Step6 from './components/steps/Step6.vue'
import Step7 from './components/steps/Step7.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  },
  data: function() {
    return {
      cardLoaded: false,
      loading: true,
    }
  },
  computed: {
    ...mapState('specialCard', ['stepsCount', 'currentStep', 'newCard']),
  },
  methods: {
    ...mapActions('specialCard', ['nextStep', 'prevStep', 'setStep', 'resetNewCard']),
    // save() {
    //   if (this.$route.meta == 'copy') {
    //     this.$axios.post('/special-card/card/copy', this.newCard, {
    //       params: { id: this.$route.query.id }
    //     }).then(r => {
    //       this.resetNewCard()
    //       this.$router.push('/special-card/card/index')
    //       this.cardLoaded = false
    //     })
    //   } else if (this.$route.meta == 'update') {
    //     this.$axios.post('/special-card/card/update', this.newCard, {
    //       params: { id: this.$route.query.id }
    //     }).then(r => {
    //       this.resetNewCard()
    //       this.$router.push(this.$store.state.specialCard.fromUrl)
    //       this.cardLoaded = false
    //     })
    //   } else {
    //     this.$axios.post('/special-card/card/create', this.newCard).then(r => {
    //       this.resetNewCard()
    //       this.$router.push('/special-card/card/index')
    //       this.cardLoaded = false
    //     })
    //   }
    // }
  },
  // beforeRouteEnter (to, from, next) {
  //   Store.commit('specialCard/setFromUrl', from)
  //   next()
  // },
  mounted() {
    if (this.$route.meta == 'update' || this.$route.meta == 'copy') {
      // this.$store.dispatch('specialCard/loadCard', this.$route.query.id).finally(() => {
        this.cardLoaded = true
        this.loading = false
      // })
    } else {
      this.cardLoaded = true
    }
    // if (this.$route.query.step != this.currentStep) {
    //   this.$router.replace({ query: {
    //     ...this.$route.query,
    //     step: this.currentStep,
    //   }}).catch(() => {})
    // }
  }
}
</script>

<style>
  .bordered {
    border-style: solid;
    border-width: thin;
  }
</style>
